<template>
  <div class="header">
    <div class="logo">
      <img src="../assets/images/logo.png" />
      <span>ChannelLink</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped lang="less">
.header {
  height: 48px;
  line-height: 48px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  margin-bottom: 12px;
  .logo {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      margin-right: 12px;
      z-index: 2;
    }
    span {
      font-weight: 500;
      font-size: 18px;
    }
  }
  .tab {
    font-weight: 700;
    font-size: 14px;
  }
}
@media (min-width: 500px) {
  .header {
    height: 66px;
    line-height: 66px;
    .logo {
      img {
        width: 36px;
      }
    }
  }
}
</style>
