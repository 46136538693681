import Vue from "vue";
import VueRouter from "vue-router";
const ChannelVideo = () => import("@/views/ChannelVideo");
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    redirect: {
      name: "channelVideo",
    },
  },
  {
    path: "/channelVideo",
    name: "channelVideo",
    component: ChannelVideo,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
