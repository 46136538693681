import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
Vue.use(ElementUI, { locale });
import "./assets/css/common.less";
// eslint-disable-next-line no-unused-vars
// import vConsole from "./assets/js/vconsole.js";
import NoSleep from "nosleep.js/dist/NoSleep.min.js";
// import FastClick from "fastclick";
// FastClick.attach(document.body);
Vue.prototype.$NoSleep = NoSleep;
Vue.config.productionTip = false;
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
