<template>
  <div id="app">
    <Header></Header>
    <router-view />
  </div>
</template>
<script>
import Header from "./components/Header";
export default {
  name: "App",
  components: {
    Header,
  },
  data() {
    return {
      oldVersion: "",
      newVersion: "1.0.15",
      group: "",
      channel: "",
      activeGroup: "",
      isPc: false,
    };
  },
  created() {
    this.isPCMethod();
    this.oldVersion = localStorage.getItem("channelLinkVersion");
    if (this.oldVersion && this.oldVersion !== this.newVersion) {
      this.$store.commit("changesUpdated", true);
      console.log(this.oldVersion, "------版本更新-------", this.newVersion);
      this.group = localStorage.getItem("groupList");
      this.channel = localStorage.getItem("allChannelList");
      this.activeGroup = localStorage.getItem("activeGroup");
      // // 通知ios app版本更新
      // if (!this.isPc) {
      //   window.webkit.messageHandlers.clearCacheAndReloadWebView.postMessage(
      //     JSON.stringify({})
      //   );
      // }
      localStorage.setItem("groupList", this.group);
      localStorage.setItem("allChannelList", this.channel);
      localStorage.setItem("activeGroup", this.activeGroup);
    } else {
      localStorage.setItem("channelLinkVersion", this.newVersion);
      this.$store.commit("changesUpdated", false);
      console.log("版本未更新");
    }
  },
  methods: {
    isPCMethod() {
      // 判断平台
      const userAgentInfo = navigator.userAgent;
      const Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];

      let flag = true;

      for (let v = 0; v < Agents.length; v++) {
        if (
          userAgentInfo.indexOf(Agents[v]) > 0 ||
          (/Mac/.test(navigator.userAgent) && navigator.maxTouchPoints > 0)
        ) {
          flag = false;
          break;
        }
      }
      this.isPc = flag;
      this.$store.commit("changesIsPc", flag);
    },
  },
};
</script>
